import payload_plugin_Tle7as8sRA from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.1_typescript@4.9.5_vue@3.5.13_typescript@4.9.5__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_wwke4uG3ps from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7fkhpIJguI from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_li7e82Dywg from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_bwAmN39nTW from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_S1yFsytpu0 from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SoEPCd1aUZ from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ngsc5FKGuq from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.1_typescript@4.9.5_vue@3.5.13_typescript@4.9.5__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/.nuxt/components.plugin.mjs";
import prefetch_client_rbzep9LVf0 from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.1_@types+node@20.17.23_db0@0.3.1_eslint@8.57.1_ioredis@5.5.0__zfpdkrtkv6sxylgkanvwx6zi3q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import unocss_MzCDxu9LMj from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/.nuxt/unocss.mjs";
import sentry_client_fymoo67wuL from "/builds/asar-studio/ticket-me-biz/ticketme/libs/common/dist/runtime/plugins/sentry.client.js";
import plugin_EOu28abW8m from "/builds/asar-studio/ticket-me-biz/ticketme/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.8.0_magicast@0.3.5_pinia@2.3.1_typescri_3s7xolmikrmjrxnxuhxymakcbe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import blinkloader_client_hEI320JLG9 from "/builds/asar-studio/ticket-me-biz/ticketme/web/shop/src/plugins/blinkloader.client.ts";
export default [
  payload_plugin_Tle7as8sRA,
  revive_payload_client_wwke4uG3ps,
  unhead_7fkhpIJguI,
  router_li7e82Dywg,
  navigation_repaint_client_bwAmN39nTW,
  check_outdated_build_client_S1yFsytpu0,
  chunk_reload_client_SoEPCd1aUZ,
  plugin_vue3_ngsc5FKGuq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_rbzep9LVf0,
  unocss_MzCDxu9LMj,
  sentry_client_fymoo67wuL,
  plugin_EOu28abW8m,
  blinkloader_client_hEI320JLG9
]